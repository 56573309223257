import {useEffect} from 'react'
import useIsBrowser from 'hooks/useIsBrowser'

import {addExternalScript} from 'helpers/utils'

const useShareToMicrosoftTeams = () => {
  const renderShareMicrosoftTeamsButtons = () =>
    useIsBrowser && window.shareToMicrosoftTeams.renderButtons()

  useEffect(() => {
    addExternalScript('https://teams.microsoft.com/share/launcher.js', () =>
      renderShareMicrosoftTeamsButtons(),
    )
  }, [])
}

export default useShareToMicrosoftTeams
