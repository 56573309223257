import * as R from 'ramda'
import React from 'react'

import {heroInsightPropTypes} from 'helpers/propTypes'
import BackButton from 'components/UI/BackButton'
import HashTags from 'components/UI/HashTags'
import Media from 'components/UI/Media'
import RoundButton from 'components/UI/RoundButton'
import Section from 'components/UI/Section'
import Title from 'components/UI/Title'

import useStyles from './style'

const Hero = ({
  author,
  image,
  hatTitle,
  title,
  type,
  backButtonLabel,
  backButtonHasShortLink,
  isPreview,
  callToAction,
  externalLink,
  navLocale,
  countryCode,
  backButtonLinkPrefix,
}) => {
  const classes = useStyles()
  const backLink = backButtonHasShortLink
    ? `../${countryCode}/${navLocale}/${backButtonLinkPrefix}`
    : `../../${backButtonLinkPrefix}`

  const typeIsObject = R.type(type) === 'Object'
  const typeIsArray = R.type(type) === 'Array'

  return (
    <Section hasPaddingSide hasPaddingBottom={false}>
      <BackButton
        backLink={backLink}
        backLabel={backButtonLabel}
        positionAbsolute
      />
      <div className={classes.heroPadding}>
        <div className={classes.container}>
          {typeIsArray && <HashTags isDisabled tags={type} />}
          <Title type="mainTitle" hat={typeIsObject ? type.name : hatTitle}>
            {title}
          </Title>
          <Title color="gray" type="smallTitle" fontFamily="medium">
            {author}
          </Title>
          {isPreview && (
            <div
              className={classes.previewImage}
              style={{backgroundImage: `url(${image})`}}
            />
          )}
          {image && !isPreview ? (
            <div className={classes.heroImage}>
              <Media data={image} is169Ratio />
            </div>
          ) : null}
          {callToAction && (
            <RoundButton
              color="secondary"
              arrow
              isCentered
              href={externalLink}
              externalLink
              isNowrap
            >
              {callToAction}
            </RoundButton>
          )}
        </div>
      </div>
    </Section>
  )
}

Hero.propTypes = heroInsightPropTypes.isRequired

export default Hero
