import classNames from 'classnames'
import React from 'react'

import {socialSharePropTypes} from 'helpers/propTypes'
import Section from 'components/UI/Section'
import Title from 'components/UI/Title'
import useShareToMicrosoftTeams from 'hooks/useShareToMicrosoftTeams'

import useStyles from './styles'

const SocialShare = ({title, label, isVertical, redirect}) => {
  const classes = useStyles()
  const url = redirect
  const whatsappShareLink = `https://wa.me/?text=${encodeURI(title)}%20${url}`
  const twitterShareLink = `https://twitter.com/intent/tweet?text=${encodeURI(
    title,
  )} ${url}`
  const linkedInShareLink = `https://www.linkedin.com/shareArticle?mini=true&url=${url}`
  const facebookShareLink = `https://www.facebook.com/sharer/sharer.php?u=${url}`
  const workplaceShareLink = `https://work.facebook.com/sharer.php?display=page&u=${url}`

  useShareToMicrosoftTeams()

  const renderSocialShareButtons = () => (
    <>
      <a
        href={whatsappShareLink}
        className={classes.shareButton}
        rel="noreferrer"
        target="_blank"
      >
        <img
          src="//images.ctfassets.net/te2janzw7nut/3AvfITKU0vAmGs5nLnqPJi/580c92eb50e67ea947d0550a6c923f66/whatsapp.svg"
          alt="whatsapp"
          loading="lazy"
        />
      </a>
      <a
        href={twitterShareLink}
        className={classes.shareButton}
        rel="noreferrer"
        target="_blank"
      >
        <img
          src="//images.ctfassets.net/te2janzw7nut/3RMcmL3B4q2OPMJKUiqXGE/aa90f152c4b057600a5a3934e969459a/twitter.svg"
          alt="twitter"
          loading="lazy"
        />
      </a>
      <div
        href={url}
        className={classNames('teams-share-button', classes.shareButton)}
        rel="noreferrer"
        target="_blank"
      />
      <a
        href={linkedInShareLink}
        className={classes.shareButton}
        rel="noreferrer"
        target="_blank"
      >
        <img
          src="//images.ctfassets.net/te2janzw7nut/jXPrxjuGxAMSsmLcAwNfJ/ccbbfe59eb1ae3e1b07b0f2ddfade8df/linkedin.svg"
          alt="linkedin"
          loading="lazy"
        />
      </a>
      <a
        href={facebookShareLink}
        className={classes.shareButton}
        rel="noreferrer"
        target="_blank"
      >
        <img
          src="//images.ctfassets.net/te2janzw7nut/5sQxgUAPEnCFAMHEhevTO7/bb821249d3f3928471de396b68a32850/facebook.svg"
          alt="facebook"
          loading="lazy"
        />
      </a>
      <a
        href={workplaceShareLink}
        className={classes.shareButton}
        rel="noreferrer"
        target="_blank"
      >
        <img
          src="//images.ctfassets.net/te2janzw7nut/1VK2w3h7q2PJYDZAlBnW6c/a675269f6c85c8335ee99df5ed9bfab3/workplace.svg"
          alt="workplace"
          loading="lazy"
        />
      </a>
    </>
  )

  const renderHorizontal = () => (
    <Section hasPaddingTop={false} hasPaddingBottom={false}>
      <div className={classes.container}>
        {label && (
          <Title isCentered variant="h2" type="mediumTitle" fontFamily="book">
            {label}
          </Title>
        )}
        <div className={classNames(classes.wrap)}>
          <div className={classes.links}>
            <div className={classes.logos}>{renderSocialShareButtons()}</div>
          </div>
        </div>
      </div>
    </Section>
  )

  const renderVertical = () => (
    <div className={classes.verticalContainer}>
      {label && (
        <Title variant="h2" type="mediumTitle" fontFamily="book">
          {label}
        </Title>
      )}
      <div className={classes.links}>
        <div className={classes.verticalLogos}>
          {renderSocialShareButtons()}
        </div>
      </div>
    </div>
  )

  return isVertical ? renderVertical() : renderHorizontal()
}

SocialShare.propTypes = socialSharePropTypes

SocialShare.defaultProps = {}

export default SocialShare
