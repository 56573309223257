import * as R from 'ramda'
import {graphql} from 'gatsby'
import {shape} from 'prop-types'
import React from 'react'

import {
  allContentfulInsightPropTypes,
  allContentfulInsightsPropTypes,
  locationPropTypes,
  pageContextPropTypes,
} from 'helpers/propTypes'
import {formRedirection, socialShare} from 'helpers/utils'
import Article from 'components/Insight/Article'
import Cta from 'components/Insight/Cta'
import Hero from 'components/UI/ArticleHero'
import Related from 'components/UI/Related'

import SEO from 'components/seo'
import SocialShare from 'components/UI/SocialShare'
// import SwitchNewsLetterForm from 'components/UI/SwitchNewsLetterForm'
import useIsBrowser from 'hooks/useIsBrowser'
import useIsMobile from 'hooks/useIsMobile'

const Initiative = ({pageContext, data, location}) => {
  const countryData = R.path(['contentfulCountry'], data)
  const isMobile = useIsMobile()
  const {hasShortPath, prefix} = pageContext
  const {countryCode, navLocale, newsletterCampaignToken, salesforce} = R.path(
    ['contentfulCountry'],
    data,
  )

  const relatedPrefix = hasShortPath
    ? `../${countryCode}/${navLocale}/initiatives/`
    : '../'

  const {
    insightShareButtons,
    newsletterCallToAction,
    newsletterTitle,
    newsletterDescription,
    newsletterPlaceholder,
    newsletterImage: {file},
    newsletterGdprConsent,
  } = R.pathOr(null, ['allContentfulInsights', 'nodes', 0], data)

  const {initiativeBackButtonLabel, initiativeOtherArticlesLabel} = R.pathOr(
    null,
    ['allContentfulInitiatives', 'nodes', 0],
    data,
  )

  const {
    slug,
    content,
    title,
    image,
    metaTitle,
    metaDescription,
    pillars,
    calToAction,
    externalLink,
  } = R.pathOr(null, ['allContentfulInitiative', 'nodes', 0], data)

  const relatedInitiative = R.pathOr(null, ['relatedInitiative', 'edges'], data)

  const siteURL = R.pathOr(null, ['site', 'siteMetadata', 'siteUrl'], data)

  const hasImageAndTitle =
    image && (image.gatsbyImageData || image.file) && title

  const hasRelatedContent = R.length(relatedInitiative) > 0

  const formRedirect = formRedirection(
    siteURL,
    countryCode,
    navLocale,
    'newsletter',
  )

  const socialShareRedirect = socialShare(
    siteURL,
    countryCode,
    navLocale,
    prefix,
    slug,
    hasShortPath,
  )

  const richTextDocuments = R.pathOr(null, ['richTextDocuments', 'nodes'], data)

  const pageMetaData = {
    article: true,
    image: image && R.pathOr(' ', ['gatsbyImageData'], image),
    metaTitle: R.pathOr('', ['metaTitle'], metaTitle) || metaTitle,
    metaDescription: R.pathOr('', ['metaDescription'], metaDescription),
    siteMetaData: R.pathOr('', ['siteMetaData'], data),
    genericData: R.pathOr('', ['contentfulGenericData'], data),
  }

  return (
    <>
      <SEO
        pageContext={pageContext}
        countryData={countryData}
        pageMetaData={pageMetaData}
      />
      {hasImageAndTitle && (
        <Hero
          image={image}
          title={title}
          type={pillars}
          backButtonLabel={initiativeBackButtonLabel}
          backButtonHasShortLink={hasShortPath}
          backButtonLinkPrefix={
            process.env.GATSBY_WEBSITE === 'keyrus-fondation'
              ? 'news'
              : 'initiatives'
          }
          navLocale={navLocale}
          countryCode={countryCode}
        />
      )}

      <Cta calToAction={calToAction} externalLink={externalLink} />

      <Article
        content={content}
        services={pillars}
        richTextDocuments={richTextDocuments}
      />
      <SocialShare
        title={title}
        location={useIsBrowser && location}
        siteURL={siteURL}
        socialMediaLinks={insightShareButtons}
        isVertical={!isMobile}
        redirect={socialShareRedirect}
      />
      {/* <SwitchNewsLetterForm
        campaignToken={newsletterCampaignToken}
        hasLightGreyBackground
        hubSpotCountry={salesforce}
        isArticle
        locale={navLocale}
        location={location}
        newsLetterCallToAction={newsletterCallToAction}
        newsLetterDescription={newsletterDescription}
        newsLetterGdprConsent={newsletterGdprConsent}
        newsLetterImage={file}
        newsLetterPlaceholder={newsletterPlaceholder}
        newsLetterTitle={newsletterTitle}
        redirect={formRedirect}
      /> */}
      {hasRelatedContent && !isMobile && (
        <Related
          cards={relatedInitiative}
          title={initiativeOtherArticlesLabel}
          cardSize="wide"
          buttonsPosition="bottom"
          prefix={relatedPrefix}
        />
      )}
    </>
  )
}

Initiative.propTypes = {
  data: shape({
    allContentfulInsight: allContentfulInsightPropTypes,
    allContentfulInsights: allContentfulInsightsPropTypes,
  }).isRequired,
  location: locationPropTypes.isRequired,
  pageContext: pageContextPropTypes.isRequired,
}

Initiative.defaultProps = {}

export default Initiative

export const pageQuery = graphql`
  query templateInitiative(
    $nodeLocale: String
    $contentId: String
    $technicalName: String
  ) {
    allContentfulInitiative(
      filter: {node_locale: {eq: $nodeLocale}, contentful_id: {eq: $contentId}}
    ) {
      nodes {
        slug
        title
        node_locale
        contentful_id
        publicationDate(formatString: "DD.MM.YY")
        image {
          file {
            url
            contentType
          }
          gatsbyImageData(placeholder: BLURRED)
        }
        metaTitle {
          metaTitle
        }
        metaDescription {
          metaDescription
        }
        pillars {
          name
        }
        calToAction
        externalLink
        content {
          raw
          references {
            ... on ContentfulAsset {
              description
              contentful_id
              file {
                url
                contentType
              }
              __typename
            }
            ... on ContentfulEntry {
              contentful_id
              __typename
              ... on ContentfulDocument {
                name
              }
              ... on ContentfulCallToAction {
                contentful_id
                __typename
                callToActionText
                callToActionLink
              }
            }
          }
        }
      }
    }
    relatedInitiative: allContentfulInitiative(
      filter: {
        countriesOfDestination: {
          elemMatch: {technicalName: {eq: $technicalName}}
        }
        node_locale: {eq: $nodeLocale}
        contentful_id: {ne: $contentId}
      }
      limit: 10
      sort: {publicationDate: DESC}
    ) {
      edges {
        node {
          ...initiativeFields
          metaDescription {
            metaDescription
          }
          image {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
            description
          }
        }
      }
    }
    allContentfulInsights(filter: {node_locale: {eq: $nodeLocale}}) {
      nodes {
        insightOtherArticlesLabel
        insightShareButtons {
          icon {
            file {
              url
            }
          }
        }
        node_locale
        newsletterCallToAction
        newsletterDescription
        newsletterPlaceholder
        newsletterTitle
        newsletterImage {
          file {
            url
            fileName
          }
        }
        newsletterGdprConsent {
          raw
        }
      }
    }
    allContentfulInitiatives(filter: {node_locale: {eq: $nodeLocale}}) {
      nodes {
        initiativeBackButtonLabel
        initiativeOtherArticlesLabel
      }
    }
    contentfulCountry(
      node_locale: {eq: $nodeLocale}
      technicalName: {eq: $technicalName}
    ) {
      ...countryDataFields
      newsletterCampaignToken
      id
      name
    }
    allContentfulService(filter: {node_locale: {eq: $nodeLocale}}) {
      edges {
        node {
          ...serviceFields
        }
      }
    }
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    siteMetaData: site {
      ...SiteMetadata
    }
    contentfulGenericData {
      genericKeywords {
        content
      }
    }
    richTextDocuments: allContentfulDocument(
      filter: {node_locale: {eq: "en-US"}}
    ) {
      nodes {
        contentful_id
        name
      }
    }
  }
`
